import React from 'react'

import './style/trademark-disputes.scss'

const TrademarkDisputes = () => (
  <div className="row-wrapper copy-right trademark-disputes">
    <div className="container">
      <section className="policy-agree">
        <div className="row">
          <div className="col-sm-12">
            <h2>WebsiteBuilder Trademark Disputes for Domain Names</h2>
          </div>
        </div>
      </section>
    </div>
    <div className="container tos">
      <h1 className="h2">WebsiteBuilder Trademark Disputes for Domain Names</h1>
      <div className="row">
        <div className="col-xs-12">
          <ul className="legal">
            <p></p><p>If you have a dispute regarding your trademark being used within a domain name registered through us, please refer to ICANN’s Uniform Domain-Name
              Dispute-Resolution Policy ("UDRP") (<a href="https://www.icann.org/resources/pages/help/dndr/udrp-en">available here</a>) for information about your
              options. </p><p>If you have a complaint about the use of your trademark on a website hosted by us, please email us at <a href="mailto:abuse@websitebuilder.com">abuse@websitebuilder.com</a>.</p>

          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default TrademarkDisputes
